<template>
  <div>
    <el-upload action=""
               :show-file-list="false" accept="xlsx"
               :on-success="handleImportSuccess"
               style="display: inline-block;margin-right: 5px"
              :http-request="upload">
      <el-button type="primary">导入 <i class="el-icon-bottom"></i></el-button>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: "testimport",
  methods:{
    handleImportSuccess(){
      this.$message.success("导入成功")
      this.load();
    },
    upload(param){
      this.axios.post("http://localhost:8085/sink/importtest", (responseimg) => {
      },{
        file: param.file
      })
    }
  }
}
</script>

<style scoped>

</style>